<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        <b>{{
          inputs.language === 'en' ? 'Select the best answer' : 'Sélectionnez la meilleure réponse'
        }}</b
        >.
        {{
          inputs.language === 'en'
            ? 'Which of the following solvent systems would be a poor choice for liquid-liquid extraction?'
            : 'Lequel de ces systèmes de solvant serait un mauvais choix pour une extraction liquide-liquide?'
        }}
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab3Q1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'Dichloromethane/water', value: 'dichloromethane'},
        {text: 'Ethanol/water', value: 'ethanol'},
        {text: 'Hexanes/water', value: 'hexanes'},
        {text: 'Ether/water', value: 'ether'},
        {text: 'Ethyl acetate/water', value: 'ethylAcetate'},
      ],
      optionsFr: [
        {text: 'Dichlorométhane/eau', value: 'dichloromethaneFr'},
        {text: 'Éthanol/eau', value: 'ethanolFr'},
        {text: 'Éther/eau', value: 'etherFr'},
        {text: "Acétate d'éthyle/eau", value: 'ethylAcetateFr'},
        {text: 'Hexanes/eau', value: 'hexanesFr'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
